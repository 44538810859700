<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="mobile"
                    label="电话"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorLevelId"
                    label="分销等级"
                >
                    <el-select
                        v-model="queryFormModel.distributorLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in distributorLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="billName"
                    label="账单名称"
                >
                    <el-input
                        v-model="queryFormModel.billName"
                        placeholder="请输入账单名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="billTime"
                    label="账单生成时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.billTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="isSettlement"
                    label="是否结算完成"
                >
                    <el-select
                        v-model="queryFormModel.isSettlement"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                >
                    <el-input
                        v-model="queryFormModel.remarks"
                        placeholder="请输入备注"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="billName"
                    label="账单名称"
                    min-width="100"
                />
                <el-table-column
                    label="账单生成时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.billCreateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="账单时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.billBeginTime | moment('YYYY-MM-DD') }}
                        <div>{{ scope.row.billEndTime | moment('YYYY-MM-DD') }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="distributorLevelName"
                    label="分销等级"
                    min-width="100"
                />
                <el-table-column
                    prop="mobile"
                    label="电话"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.countryCode }}-{{ scope.row.mobile }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="billTotalAmount"
                    label="账单总金额"
                    min-width="100"
                />
                <el-table-column
                    prop="settlementAmount"
                    label="结算金额"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <span :class="{'tc-red':scope.row.billTotalAmount > scope.row.settlementAmount}">{{ scope.row.settlementAmount }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/buyerBill/buyerBillDistributionDetail/' + scope.row.id)"
                        >
                            查看详情
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                            v-if="scope.row.billTotalAmount !== scope.row.settlementAmount && scope.row.settlementAmount === 0"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'BuyerBillOrder',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                mobile: '',
                distributorLevelId: '',
                billName: '',
                billTime: '',
                billType: '2',
                isSettlement: '',
                remarks: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 等级列表
            distributorLevelList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.BuyerBill.buyerBillList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        initLevelList() {
            this.$api.Mb.DistributorLevel.select().then(json => {
                const res = json.data.data;
                this.distributorLevelList = res;
            });
        },
        onTableDelete(id) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Mb.BuyerBill.delete({ id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
    created() {
        this.initLevelList();
    },
};
</script>

<style lang="scss">
</style>

<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template
            slot="body"
        >
            <el-form
                size="small"
            >
                <el-card
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>账单信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="账单名称:"
                                label-width="8em"
                            >
                                {{ buyerBill.billName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="账单生成时间:"
                                label-width="8em"
                            >
                                {{ buyerBill.billCreateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="手机号:"
                                label-width="8em"
                            >
                                {{ buyerBill.mobile }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="buyerBill.billType === 1"
                        >
                            <el-form-item
                                label="会员等级:"
                                label-width="8em"
                            >
                                {{ buyerBill.buyerLevelName }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="buyerBill.billType === 2"
                        >
                            <el-form-item
                                label="分销等级:"
                                label-width="8em"
                            >
                                {{ buyerBill.distributorLevelName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="账单开始时间:"
                                label-width="8em"
                            >
                                {{ buyerBill.billBeginTime | moment('YYYY-MM-DD') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="账单截止时间:"
                                label-width="8em"
                            >
                                {{ buyerBill.billEndTime | moment('YYYY-MM-DD') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="账单总金额:"
                                label-width="8em"
                            >
                                {{ buyerBill.billTotalAmount }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="结算金额:"
                                label-width="8em"
                            >
                                {{ buyerBill.settlementAmount }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="备注:"
                                label-width="8em"
                            >
                                {{ buyerBill.remarks }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>账单详情</span>
                    </div>
                    <div class="item">
                        <el-col :span="24">
                            <!-- 表单区 -->
                            <el-form
                                ref="queryForm"
                                class="second-header npa-b"
                                size="small"
                                :inline="true"
                                :model="queryFormModel"
                            >
                                <el-form-item
                                    prop="orderSn"
                                    label="订单编号"
                                >
                                    <el-input
                                        v-model="queryFormModel.orderSn"
                                        placeholder="请输入订单编号"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="orderTime"
                                    label="下单时间"
                                >
                                    <el-date-picker
                                        :picker-options="$utils.CommonConfig.pickerOptions"
                                        v-model="queryFormModel.orderTime"
                                        type="daterange"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                    />
                                </el-form-item>
                                <el-form-item>
                                    <el-button
                                        type="primary"
                                        @click="onQuery()"
                                    >
                                        查询
                                    </el-button>
                                    <el-button
                                        type="success"
                                        @click="onReset"
                                    >
                                        重置
                                    </el-button>
                                </el-form-item>
                            </el-form>

                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="tableData"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="orderSn"
                                    label="订单编号"
                                    min-width="100"
                                />
                                <el-table-column
                                    label="下单时间"
                                    min-width="150"
                                >
                                    <template slot-scope="scope">
                                        {{ scope.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="orderStatusName"
                                    label="订单状态"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="billAmount"
                                    label="账单金额"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="remarks"
                                    label="账单备注"
                                    min-width="100"
                                />
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    width="60"
                                >
                                    <template slot-scope="order">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="$router.push('/order/order/orderDetail/' + order.row.orderId)"
                                        >
                                            查看
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <!-- 分页 -->
                            <div class="ma-t ta-r">
                                <pagination
                                    v-model="pagination"
                                    @input="onQuery"
                                />
                            </div>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                            <span>结算详情</span>
                            <div>
                                <el-button
                                    size="mini"
                                    type="success"
                                    icon="el-icon-edit"
                                    @click="onAdd"
                                    v-if="buyerBill.billTotalAmount !== buyerBill.settlementAmount"
                                >
                                    新增结算单
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="buyerBillSettlementList"
                                style="width: 100%"
                                ref="selectDialogTable"
                            >
                                <el-table-column
                                    prop="settlementAmount"
                                    label="结算金额"
                                    width="80"
                                />
                                <el-table-column
                                    prop="settlementTime"
                                    label="结算时间"
                                    width="135"
                                >
                                    <template v-slot="scope">
                                        {{ scope.row.settlementTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="remarks"
                                    label="备注"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="operatorName"
                                    label="操作人"
                                    width="100"
                                />
                                <el-table-column
                                    label="结算附件"
                                    min-width="200"
                                >
                                    <template
                                        v-slot="scope"
                                    >
                                        <ImageList
                                            :data="scope.row.settlementAttachmentList"
                                        />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </div>
                </el-card>
            </el-form>
        </template>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            title="生成账单"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="settlementAmount"
                    label="结算金额"
                    label-width="6em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.settlementAmount"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="6em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    label="附件"
                    label-width="6em"
                    prop="settlementAttachment"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="10"
                        v-model="addDialog.formModel.settlementAttachment"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orderSn: '',
                orderTime: '',
                buyerId: '',
                billType: '',
                buyerBillId: '',
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    settlementAmount: '',
                    remarks: '',
                    settlementAttachment: [],
                },
                // 表单校验规则
                formRules: {
                    settlementAmount: {
                        required: true,
                        message: '请输入结算金额',
                        trigger: 'blur',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            buyerBill: {},
            buyerBillSettlementList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.BuyerBill.buyerBillDetailList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.BuyerBill.createBuyerBillSettlement({
                    ...this.addDialog.formModel,
                    buyerBillId: this.queryFormModel.buyerBillId,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        init() {
            this.$api.Mb.BuyerBill.buyerBillDetail({ id: this.queryFormModel.buyerBillId }).then(json => {
                const res = json.data.data;
                this.buyerBill = res.buyerBill;
                this.buyerBillSettlementList = res.buyerBillSettlementList || [];
            });
        },
    },
    created() {
        this.queryFormModel.buyerBillId = this.$route.params.id;
        this.init();
    },
};
</script>

<style lang="scss">
</style>
